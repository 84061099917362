import React from "react";
import { motion } from "framer-motion";

export default function ProgressBar({ value, delay }) {
  return (
    <div className="progressbar-container">
      <div className="progressbar">
        <motion.div
          className="bar"
          transition={{ duration: 2, delay: delay }}
          animate={{
            width: `${value}%`,
          }}
        ></motion.div>
      </div>
    </div>
  );
}
