import { React, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// pages
import HomePage from "../pages/HomePage";
import Projects from "../pages/Projects";
import PhotographyPortfolio from "../pages/Photography";
import PageNotFound from "../pages/PageNotFound";
import SkillSet from "../pages/Skills";
import Contact from "../pages/Contact";
import AboutMe from "../pages/AboutMe";

// projects
import UBEX from "../pages/projectpages/UBEX";
import MapPage from "../pages/projectpages/MapPage";
import ExpertAnalytics from "../pages/projectpages/ExpertAnalytics";
import TransformIT from "../pages/projectpages/TransformIT";
import Cisco from "../pages/projectpages/Cisco";
import NewsApp from "../pages/projectpages/NewsApp";
import TravelSite from "../pages/projectpages/TravelSite";
import PowerPlatform from "../pages/projectpages/PowerPlatform";
import DigitalTwin from "../pages/projectpages/DigitalTwin";

// gallery subpages
import RekaAndDani from "../pages/projectpages/RekaAndDani";
import WowHungary from "../pages/projectpages/WowHungary";
import Budapest100 from "../pages/projectpages/Budapest100";

// animation
import { AnimatePresence } from "framer-motion";
import AnimatedPage from "./AnimatedPage";
import SplineCube from "./SplineCube";

export default function AnimatedRoutes() {
  const location = useLocation();

  // get url param
  const urlParam = new URLSearchParams(window.location.search).get("source");

  // check entry points
  const [isCVSource, setCVSource] = useState(urlParam === "cv");
  const [isTestingEnv, setTestingEnv] = useState(urlParam === "testing");

  useEffect(() => {
    const checkCVSource = () => {
      switch (urlParam) {
        case "cv":
          setCVSource(true);
          localStorage.setItem("cv", urlParam);
          break;
        case "testing":
          setTestingEnv(true);
          localStorage.removeItem("cv");
          break;
        default:
          break;
      }
    };
    checkCVSource();

    return () => {
      checkCVSource();
    };
  }, [urlParam]);

  // pushing through the url the url if entry point was my cv or testing part
  if (isCVSource) {
    const currentUrl = window.location.pathname;
    const pushedUrl = `${currentUrl}?source=cv`;
    window.history.replaceState(null, null, pushedUrl);
  }

  if (isTestingEnv) {
    const currentUrl = window.location.pathname;
    const pushedUrl = `${currentUrl}?source=testing`;
    window.history.replaceState(null, null, pushedUrl);
  }

  return (
    <AnimatePresence mode="wait">
      <Routes location={location}>
        {/* general */}
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutMe />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route path="/photography" element={<PhotographyPortfolio />} />

        <Route path="/skills" element={<SkillSet />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<PageNotFound />} />

        {/* project pages */}
        <Route
          path="/photography/reka-and-dani-engagement"
          element={<RekaAndDani />}
        />
        <Route path="/photography/wow-hungary" element={<WowHungary />} />
        <Route path="/photography/budapest-100" element={<Budapest100 />} />
        <Route
          exact
          path="/web/interactive-map-visualiser"
          element={<MapPage />}
        />
        <Route
          exact
          path="/web/software-capability-walkthrough"
          element={<ExpertAnalytics />}
        />
        <Route exact path="/web/transform-it" element={<TransformIT />} />
        <Route
          exact
          path="/web/ubex-product-family-landing-page"
          element={<UBEX />}
        />
        <Route
          exact
          path="/web/project-configuration-panel"
          element={<DigitalTwin />}
        />

        <Route exact path="/web/cisco-integration" element={<Cisco />} />

        <Route path="/ui/news-app" element={<NewsApp />} />

        <Route path="/ui/travel-site-design" element={<TravelSite />} />

        <Route
          exact
          path="/web/power-platform-concepts"
          element={<PowerPlatform />}
        />

        {/* testing*/}
        <Route path="/spline" element={<SplineCube />} />
      </Routes>
    </AnimatePresence>
  );
}
