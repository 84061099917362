import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function GTM({ pathname }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
    }
    if (window.dataLayer.length > 150) {
      window.dataLayer = [];
    }
  });

  window.dataLayer.push(
    {
      event: "pageview",
      path: pathname,
    },
    [pathname]
  );

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WGEWCDHXZD"
        ></script>
      </Helmet>
    </>
  );
}
