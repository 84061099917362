import React from "react";
import ContactForm from "../components/ContactForm";
import AnimatedSection from "../components/AnimatedSections";
import GTM from "../components/GTM";
import HelmetHeader from "../components/HelmetHeader";

// icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MyLocationIcon from "@mui/icons-material/MyLocation";

export default function Contact() {
  // gtm
  GTM({
    pathname: "/contact",
  });

  const contactInfo = [
    {
      id: 0,
      icon: <MailOutlineIcon />,
      detail: "dorikelemen@icloud.com",
    },
    {
      id: 1,
      icon: <MyLocationIcon />,
      detail: "Budapest, Hungary",
    },
  ];

  return (
    <div className="maxContainer contact">
      <HelmetHeader title="Contact" />
      <h1>Contact</h1>
      <AnimatedSection>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <h5>Contact details</h5>

            {contactInfo.map((item) => {
              return (
                <div className="buttonContainer" style={{ margin: "1rem 0" }}>
                  {item.icon}
                  <p style={{ margin: 0 }}>{item.detail}</p>
                </div>
              );
            })}
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <ContactForm />
          </div>
        </div>
      </AnimatedSection>
    </div>
  );
}
