import React, { useEffect, useState } from "react";
import BlurHash from "./BlurHash";
import AnimatedSection from "./AnimatedSections";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// modal
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

// base mockups
import macBook from "../assets/macbook-mockup.png";
import ipad from "../assets/ipad-mockup.png";

export default function ComponentCards(props) {
  // background fetch
  const backgroundFetch = require(`../assets/project-items/${props.cardphoto}`);

  // open corresponding
  const [modalOpen, setModalOpen] = useState(false);

  const openModalContent = () => {
    setModalOpen(true);
  };

  const closeModalContent = () => {
    setModalOpen(false);
  };

  /* image source */
  const imageConstruct = require(`../assets/project-items/${props.mainphoto}`);

  // mockups and variations
  const [hasMockup, setHasMockup] = useState(false);

  // variations
  const desktopDemo = props.mockupVariant === "desktop";
  const tabletDemo = props.mockupVariant === "tablet";

  useEffect(() => {
    const showMockupBox = () => {
      if (props.mockup === true) {
        setHasMockup(true);
      } else {
        setHasMockup(false);
      }
    };

    showMockupBox();

    return () => {
      setHasMockup();
    };
  });

  return (
    <>
      <div className="col-lg-4 col-md-4 col-6">
        <AnimatedSection transition={{ delay: 0.2, duration: 0.7 }}>
          <div className="outerComponentCard">
            <div className="componentcard">
              <img
                src={backgroundFetch}
                alt={`${props.name} project `}
                title={`${props.name} project`}
                width={100}
              />
              <div className="topCategory">
                <div className="technologyCategories">
                  <span>{props.category}</span>
                </div>
              </div>
              <div className="projectDetail">
                <h3>{props.name}</h3>
                <button className="chevronButton" onClick={openModalContent}>
                  <p>Open info</p>
                  <OpenInNewIcon />
                </button>
              </div>
            </div>
          </div>
        </AnimatedSection>
      </div>
      {/* modal */}
      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={modalOpen}
        onHide={closeModalContent}
        backdrop="static"
        className="componenDemoModal"
        centered
      >
        <Modal.Body>
          <CloseIcon onClick={closeModalContent} className="closeDownBtn" />
          <div className="row align-items-start">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="modalContainer">
                <h3>{props.name}</h3>
                {props.description.map((item, i) => {
                  return <p key={i}>{item}</p>;
                })}
                <div className="projectTechnologies">
                  {props.technologies.map((item, i) => {
                    return (
                      <p className="technologyCategories" key={i}>
                        {item}
                      </p>
                    );
                  })}
                </div>
                <div className="keyInfoContainer">
                  <div className="highlightInformation">
                    <p>Date</p>
                    <h4>{props.date}</h4>
                  </div>
                  {props.company !== "" && (
                    <div className="highlightInformation">
                      <p>Company</p>
                      <h4>{props.company}</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 col-12 ">
              {hasMockup ? (
                <>
                  {/* desktop render */}
                  {desktopDemo && (
                    <div className="componentScrollShowcase">
                      <div className="macMockup">
                        <BlurHash
                          src={require(`../assets/project-items/${props.mainphoto}`)}
                          alt={`${props.name} main project photo`}
                        ></BlurHash>
                      </div>

                      <img
                        src={macBook}
                        width={100}
                        className="macBook-bgMockup"
                        alt="macBook mockup"
                      />
                    </div>
                  )}

                  {/* tablet render */}
                  {tabletDemo && (
                    <div
                      className={`componentScrollShowcase ${
                        tabletDemo && "tabletView"
                      }`}
                    >
                      <div
                        className={`macMockup ${tabletDemo && "ipadMockup"}`}
                      >
                        <BlurHash
                          src={require(`../assets/project-items/${props.mainphoto}`)}
                          alt={`${props.name} main project photo`}
                        ></BlurHash>
                      </div>
                      <img
                        src={ipad}
                        width={100}
                        className="macBook-bgMockup"
                        alt="ipad mockup"
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="backgroundPhoto">
                  <BlurHash
                    src={imageConstruct}
                    alt={`${props.name} main project photo`}
                  ></BlurHash>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
