import { useState } from "react";

export default function SlowLoad() {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleSplineLoad = () => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  };
  return {
    isLoaded,
    setIsLoaded,
    handleSplineLoad,
  };
}
