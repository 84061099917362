import React, { useEffect, useState } from "react";
import NoIndex from "../../components/NoIndex";
import AnimatedSection from "../../components/AnimatedSections";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import PowerPlatfomCard from "../../components/PowerPlatformCard";
import HelmetHeader from "../../components/HelmetHeader";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// data
import data from "../../data/powerConcepts.json";

//icons
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { styled, keyframes } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function PowerPlatformConcepts() {
  const SmoothReveal = ({ children }) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    );
  };

  const concepts = data.concepts;
  // open controls
  const [displayConcept, setDisplayConcept] = useState(false);

  const toggleDisplayConcepts = (id) => {
    if (displayConcept && displayConcept.id === id) {
      setDisplayConcept(false);
    } else {
      setDisplayConcept(concepts.find((item) => item.id === id));
      console.log(id);
    }
  };

  // states
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [openLock, setOpenLock] = useState(null);

  //  lock animation
  const lockAnimation = keyframes`
0% {
  transform: rotate(0deg) scale(1);
  filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}
25% {
  transform: rotate(-45deg) scale(1.2);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
}
50% {
  transform: rotate(45deg) scale(1.4);
  filter: drop-shadow(0 -4px 4px rgba(0, 0, 0, 0.3));
}
75% {
  transform: rotate(-45deg) scale(1.2);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
}
100% {
  transform: rotate(0deg) scale(1);
  filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}
`;

  const AnimatedLockIcon = styled(LockIcon)({
    fontSize: "2rem",
    margin: "1rem auto",
  });

  const AnimatedLockOpenIcon = styled(LockOpenIcon)({
    fontSize: "2rem",
    margin: "1rem auto",
    animation: `${lockAnimation} 0.6s ease-in`,
  });

  // password hints
  const toggleHint = () => {
    setShowHint((prevState) => !prevState);
  };

  // access check
  const checkPassword = () => {
    const passwords = [{ pw: "vodka" }, { pw: "ilovedesign" }];

    // checking sources
    const getSource = new URLSearchParams(window.location.search).get("source");
    const answer = document.getElementById("password").value;
    const urlParam = new URLSearchParams(window.location.search).get(
      "password"
    );

    // checking access
    const CVCheck = getSource === "cv";
    const findPassword = passwords.find(
      (password) => password.pw.toLowerCase() === answer.toLowerCase()
    );

    const findUrl = passwords.find((password) => password.pw === urlParam);

    // if the project is locked & requires password
    if (findPassword) {
      setOpenLock(true);
      setTimeout(() => {
        setIsVerified(true);
        setErrorMessage(null);
      }, 800);
    } else {
      setOpenLock(false);
      setIsVerified(false);
      if (answer) {
        setErrorMessage(true);
      } else {
        setErrorMessage(null);
      }
    }

    // if the user was coming from the CV or the previous page had cv source
    if (CVCheck) {
      setOpenLock(true);
      setTimeout(() => {
        setIsVerified(true);
        setErrorMessage(null);
      }, 0);
    }

    // if the password is already in the url
    if (findUrl) {
      setOpenLock(true);
      setTimeout(() => {
        setIsVerified(true);
        setErrorMessage(null);
      }, 0);
    }
  };

  useEffect(() => {
    checkPassword();
    setDisplayConcept(concepts.find((item) => item.id === 0));
  }, []);

  // clearing storage on site leave
  window.addEventListener("beforeunload", function (event) {
    // Clear localStorage
    localStorage.clear();
  });

  return (
    <>
      <HelmetHeader title="Power Platform Concepts" />
      {isVerified ? (
        <>
          <NoIndex />
          <div className="extendedContainer">
            <AnimatedSection>
              <Link to="/projects" style={{ width: "fit-content" }}>
                <div className="categoryIndicator">
                  Projects |
                  <span
                    style={{
                      color: "var(--secondaryAccentColor)",
                      marginLeft: "5px",
                    }}
                  >
                    Power Apps
                  </span>
                </div>
              </Link>
            </AnimatedSection>
            <AnimatedSection delay={0.1}>
              <h1 className="showcase--subpages">Power Platform Concepts</h1>
              <div className="descriptionContainer">
                <p>
                  Here's a quick rundown of the projects that I've delivered in
                  the past years. In the development process, I'm focusing on
                  creating a real application-like interface, that offers user
                  friendly navigation and usability.
                </p>
                <p>
                  When it comes to my workflow, I make sure to invest time in
                  pre-designing the layout and interface of each app.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-12">
                  <AnimatedSection delay={0.4}>
                    <TableContainer component={Paper} className="customTable">
                      <Table sx={{ minWidth: 200 }} aria-label="projects table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Project types</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {concepts
                            .sort((a, b) => {
                              const newYear = b.year;
                              const oldYear = a.year;
                              return newYear - oldYear;
                            })
                            .map((item) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                onClick={() => toggleDisplayConcepts(item.id)}
                                className={`${
                                  displayConcept.id === item.id ? "active" : ""
                                }`}
                              >
                                <TableCell component="th" scope="row">
                                  {item.title}
                                </TableCell>
                                <TableCell align="right">
                                  <button
                                    className={`chevronButton ${
                                      displayConcept.id === item.id
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      toggleDisplayConcepts(item.id)
                                    }
                                    style={{ marginTop: 0 }}
                                  >
                                    <ArrowForwardIcon />
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AnimatedSection>
                </div>

                {/* displaying info box */}
                <div className="col-lg-9 col-md-9 col-12">
                  {displayConcept && (
                    <AnimatedSection delay={0.6}>
                      <SmoothReveal>
                        <>
                          <PowerPlatfomCard
                            title={displayConcept.title}
                            year={displayConcept.year}
                            description={displayConcept.description}
                            keyfunctionalities={
                              displayConcept.keyfunctionalities
                            }
                            connectors={displayConcept.connectors}
                            closeAction={() => setDisplayConcept(false)}
                            img={displayConcept.img}
                            hasSubContent={displayConcept.hasSubContent}
                            subContent={displayConcept.subContent}
                          />
                        </>
                      </SmoothReveal>
                    </AnimatedSection>
                  )}
                  {!displayConcept && (
                    <p>Please select an item from the left handside. </p>
                  )}
                </div>
              </div>
            </AnimatedSection>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "96vh",
            position: "relative",
          }}
        >
          <div className="passwordLocked">
            {openLock ? (
              <AnimatedLockOpenIcon id="openLock" />
            ) : (
              <AnimatedLockIcon id="openLock" />
            )}
            <h3 style={{ marginBottom: 0 }}>Hello</h3>
            <p>
              To access the selection of projects, please enter the password.
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent the form submission
                checkPassword();
              }}
              className="formSubmit"
            >
              <input
                className={`${errorMessage ? "errorState" : "passwordInput"}`}
                id="password"
                name="password"
                placeholder="enter the provided password"
              ></input>
              <button
                className="contactButton"
                style={{
                  margin: 0,
                }}
              >
                Check
              </button>
            </form>
            <div>
              {errorMessage && (
                <p className="errorFeedback">Incorrect password</p>
              )}
              <div
                className="formSubmit hintAnswer"
                onClick={toggleHint}
                style={{ gap: "5px" }}
              >
                {showHint ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                <p style={{ marginBottom: 0 }}>Need a hint?</p>
              </div>
              <div
                className={`hiddenAnswer ${
                  showHint ? "visibleComp" : "hiddenComp"
                }`}
              >
                <p
                  className={`${showHint ? "visibleComp" : "hiddenComp"}`}
                  style={{
                    fontSize: "14px",
                  }}
                >
                  You can use either the QR code or password in my CV's
                  portfolio section.
                  <br></br>
                  For further questions or problems, please{" "}
                  <Link to="/contact">contact me</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
