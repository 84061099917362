import React from "react";

// import Swiper core and required modules
import { Navigation, Pagination, Keyboard, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Carousel(props) {
  return (
    <>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={{
          clickable: "true",
        }}
        mousewheel={true}
        loop
        speed={300}
        autoplay={{
          delay: 150,
          disableOnInteraction: true,
        }}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        style={{
          borderRadius: "10px",
          border: "1px solid rgba(0,0,0,.1)",
          paddingBottom: "4rem",
        }}
      >
        {props.images.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <img
                src={require("../assets/project-items/" + item.src)}
                alt={`${props.name} main`}
                title={`${props.name} main`}
              ></img>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
