import { React, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import SlowLoad from "./SlowLoad";
import ShowOnMobile from "./ShowOnMobile";
import HelmetHeader from "./HelmetHeader";

export default function PhototPageTemplate(props) {
  // background loading
  const { isLoaded } = SlowLoad();

  // show mobile
  const { showOnMobile } = ShowOnMobile();

  // lightbox
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const openLightbox = (src) => {
    setSelectedIndex(src); // Use the id as the index
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  // construct images
  const backgroundPhoto = require(`../assets/photography/${props.folderHandling}/${props.mainPhoto}`);

  const galleryPhotos = props.images.flat().map((item) => ({
    id: item.id,
    src: require(`../assets/photography/${props.folderHandling}/${item.src}`),
    alt: item.alt,
  }));

  return (
    <>
      <HelmetHeader title={`${props.title} | Photography`} />

      {!showOnMobile && (
        <div
          className={`${
            isLoaded ? "backgroundContainer" : "backgroundContainer loaded"
          }`}
          style={{ height: "80%" }}
        >
          <div className="overlayContainer"></div>
          <img src={backgroundPhoto} width={100} alt="hidden animation" />
        </div>
      )}
      <div className="extendedContainer" style={{ height: "fit-content" }}>
        <div className="showcase--subpages">
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          {props.additional_description.length > 0 && (
            <div
              className="descriptionContainer"
              style={{ marginBottom: "1.5rem", marginTop: 0 }}
            >
              {props.additional_description.slice(1).map((item, i) => {
                return <p key={i}>{item}</p>;
              })}
              {props.additional_description.map((item, i) => {
                return <p key={i}>{item}</p>;
              })}
            </div>
          )}
        </div>
        <div className="keyInfoContainer">
          <div className="highlightInformation">
            <p>Date</p>
            <h4>{props.date}</h4>
          </div>
          {props.company === "" ? (
            ""
          ) : (
            <div className="highlightInformation">
              <p>Occasion</p>
              <h4>{props.subtitle}</h4>
            </div>
          )}
        </div>
        <Gallery
          images={galleryPhotos}
          lazyload
          rowHeight={350}
          margin={3}
          onClick={(e, img) => openLightbox(img.id)}
          enableImageSelection="false"
        />
        <ModalGateway>
          {lightboxIsOpen && (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={selectedIndex}
                views={galleryPhotos.map((image) => ({ source: image.src }))}
              />
            </Modal>
          )}
        </ModalGateway>
      </div>
    </>
  );
}
