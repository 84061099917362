import React from "react";
import { Link } from "react-router-dom";
import HelmetHeader from "../components/HelmetHeader";

export default function PageNotFound() {
  return (
    <div
      className="extendedContainer"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <HelmetHeader title="Page not found" />
      <h1>Ooopss...</h1>
      <p>Sorry, seems like this page is not ready yet. </p>
      <Link to="/">
        <button className="simpleButton">Take me somewhere safe</button>
      </Link>
    </div>
  );
}
