import React from "react";
import AnimatedSection from "./AnimatedSections";
import Header from "./HelmetHeader";

// icons
import CodeIcon from "@mui/icons-material/Code";
import GridViewIcon from "@mui/icons-material/GridView";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";

export default function MySkills() {
  const skillTree = [
    {
      id: 0,
      title: "UX/UI",
      icon: <GridViewIcon />,
      description:
        "Skilled in crafting captivating interfaces, ensuring exceptional user experiences and user engagement.",
    },
    {
      id: 1,
      title: "Development",
      icon: <CodeIcon />,
      description:
        "Proficient in modern web technologies, creating dynamic interfaces for optimal user experiences.",
    },
    {
      id: 2,
      title: "Advanced CMS",
      icon: <WysiwygIcon />,
      description:
        "Expert in advanced CMS solutions, empowering seamless content management.",
    },
  ];

  return (
    <div className="row align-items-start">
      {skillTree.map((item, i) => {
        return (
          <div className="col-lg-4 col-md-6 col-12" key={i}>
            <AnimatedSection delay={i * 0.3}>
              <div className="designContainer">
                {item.icon}
                <div>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            </AnimatedSection>
          </div>
        );
      })}
    </div>
  );
}
