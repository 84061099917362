import React from "react";

// data
import Data from "../../data/webProjects.json";
import ProjectPageTemplate from "../../components/ProjectPageTemplate";
import NoIndex from "../../components/NoIndex";

export default function NewsApp() {
  const pageData = Data.projects;

  const projectPage = pageData.map((item) => {
    return (
      <>
        <NoIndex />
        <ProjectPageTemplate
          id={item.id}
          name={item.name}
          description={item.description}
          additional_description={item.additional_description}
          date={item.date}
          company={item.company}
          purpose={item.purpose}
          images={item.images}
          mainphoto={item.mainphoto}
          hashkey={item.hashkey}
          technologies={item.technologies}
          layoutchange={item.layoutchange}
        />
      </>
    );
  });

  return projectPage[5];
}
