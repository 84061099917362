import { React } from "react";
import ProgressBar from "../components/ProgressBar";
import { AccordionDetails, AccordionSummary, Tooltip } from "@mui/material";
import AnimatedSection from "../components/AnimatedSections";
import GTM from "../components/GTM";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HelmetHeader from "../components/HelmetHeader";

// accordion only
import { Accordion } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// images
import htmlLogo from "../assets/icons/html-icon.png";
import cssLogo from "../assets/icons/css-icon.png";
import javaScriptLogo from "../assets/icons/js-logo.png";
import reactLogo from "../assets/icons/react-icon.png";
import typeScriptLogo from "../assets/icons/typescript-icon-circle.png";
import figmaLogo from "../assets/icons/Figma-logo.svg";
import xdLogo from "../assets/icons/adobe-xd.svg";
import illustratorLogo from "../assets/icons/adobe-illustrator-cc-icon.svg";
import photoshopLogo from "../assets/icons/adobe-photoshop-cc-icon.svg";
import lightroomLogo from "../assets/icons/adobe-photoshop-lightroom-cc-icon.svg";
import splineIcon from "../assets/icons/spline-icon.png";
import jsonIcon from "../assets/icons/json-icon.svg";
import pythonIcon from "../assets/icons/python-icon.svg";

// power platform
import sharepoint from "../assets/icons/sharepoint-icon.png";
import powerAutomate from "../assets/icons/powerautomate-icon.png";
import powerApps from "../assets/icons/powerapps-icon.png";
import powerPages from "../assets/icons/powerpages-icon.svg";

// cms images
import magento from "../assets/icons/magento-icon.png";
import epi from "../assets/icons/epi-icon.png";
import wordpress from "../assets/icons/wordpress-icon.png";
import drupal from "../assets/icons/drupal-icon.png";
import sitecore from "../assets/icons/sitecore-icon.png";
import { Link } from "react-router-dom";

export default function SkillSet() {
  // gtm
  GTM({
    pathname: "/skills",
  });

  const frontendKnowledge = [
    {
      id: 0,
      title: "Node.JS",
    },
    {
      id: 1,
      title: "REST API",
    },
    {
      id: 2,
      title: "Bootrstrap",
    },
    {
      id: 3,
      title: "MUI",
    },
    {
      id: 4,
      title: "Git",
    },
    {
      id: 5,
      title: "Framer",
    },
    {
      id: 6,
      title: "Responsive design",
    },
    {
      id: 7,
      title: "Accessibility",
    },
    {
      id: 8,
      title: "Azure DevOps",
    },
    {
      id: 9,
      title: "Jira",
    },
  ];

  const designKnowledge = [
    {
      id: 0,
      title: "Webflow",
    },
    {
      id: 1,
      title: "Material Design",
    },
    {
      id: 2,
      title: "Mobile frameworks",
    },
    {
      id: 3,
      title: "Prototyping",
    },
    {
      id: 4,
      title: "Wireframes",
    },
    {
      id: 5,
      title: "Figjam",
    },
    {
      id: 6,
      title: "Photo manipulating",
    },
  ];

  const cmsKnowledge = [
    {
      id: 0,
      name: "Magento 1 & 2",
      logo: magento,
      description: "E-commerce platform for scalable online stores.",
    },
    {
      id: 1,
      name: "WordPress",
      logo: wordpress,
      description:
        "CMS with customizable themes, plugins, and easy content management.",
    },
    {
      id: 2,
      name: "Sitecore XM",
      logo: sitecore,
      description: "Enterprise-level content management system.",
    },
    {
      id: 3,
      name: "Drupal",
      logo: drupal,
      description: "Flexible CMS for building websites, intranets.",
    },
    {
      id: 0,
      name: "Episerver",
      logo: epi,
      description: "Content management and digital experience platform.",
    },
  ];

  return (
    <div className="extendedContainer">
      <HelmetHeader title="Skills" />
      <AnimatedSection>
        <h1>Skills</h1>
      </AnimatedSection>

      {/* main */}
      <div className="projectSelector">
        <AnimatedSection delay={0.2}>
          <div className="skillContainer">
            <h3 style={{ margin: 0 }}>Frontend Development</h3>
            <p>
              As a self-taught frontend developer, I specialize in creating
              captivating user interfaces and seamless website experiences. With
              expertise in HTML, CSS, and JavaScript, I focus on crafting
              visually appealing and intuitive designs.
            </p>
            <ProgressBar value={65} delay={0.6} />
            <div>
              <div className="projectTechnologies">
                <Tooltip title="HTML5" placement="bottom" arrow>
                  <img id="techIcon" src={htmlLogo} alt="HTML icon"></img>
                </Tooltip>
                <Tooltip title="CSS3" placement="bottom" arrow>
                  <img src={cssLogo} alt="CSS icon"></img>
                </Tooltip>
                <Tooltip title="JavaScript" placement="bottom" arrow>
                  <img src={javaScriptLogo} alt="javaScript icon"></img>
                </Tooltip>
                <Tooltip title="React" placement="bottom" arrow>
                  <img src={reactLogo} alt="react icon"></img>
                </Tooltip>
                <Tooltip title="JSON" placement="bottom" arrow>
                  <img src={jsonIcon} alt="json icon"></img>
                </Tooltip>
                <Tooltip title="TypeScript" placement="bottom" arrow>
                  <img src={typeScriptLogo} alt="typeScript icon"></img>
                </Tooltip>
                {/*  <Tooltip title="Python basics" placement="bottom" arrow>
                  <img src={pythonIcon} alt="python icon"></img>
                </Tooltip> */}
              </div>
              <div className="softwareKnowledge">
                {frontendKnowledge.map((item, i) => {
                  return (
                    <p className="technologyCategories" key={i}>
                      {item.title}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </AnimatedSection>
        <AnimatedSection delay={0.4}>
          <div className="skillContainer">
            <h3 style={{ margin: 0 }}>UX/UI Design</h3>
            <p>
              I bring my expertise in Adobe Creative Suite, including Photoshop,
              Illustrator, and Lightroom, to create visually compelling designs.
              With prototyping tools like Figma, I transform concepts into
              seamless user experiences.
            </p>
            <ProgressBar value={85} delay={1.1} />

            <div>
              <div className="projectTechnologies">
                <Tooltip title="Figma" placement="bottom" arrow>
                  <img id="techIcon" src={figmaLogo} alt="Figma icon"></img>
                </Tooltip>
                <Tooltip title="Adobe XD" placement="bottom" arrow>
                  <img src={xdLogo} alt="adobe XD icon"></img>
                </Tooltip>
                <Tooltip title="Adobe Illustrator" placement="bottom" arrow>
                  <img src={illustratorLogo} alt="adobe Illustrator icon"></img>
                </Tooltip>
                <Tooltip title="Adobe Photoshop" placement="bottom" arrow>
                  <img src={photoshopLogo} alt="adobe photoshop icon"></img>
                </Tooltip>
                <Tooltip title="Spline 3D Design" placement="bottom" arrow>
                  <img src={splineIcon} alt="spline 3d icon"></img>
                </Tooltip>
                <Tooltip title="Adobe Lightroom" placement="bottom" arrow>
                  <img src={lightroomLogo} alt="adobe lightroom icon"></img>
                </Tooltip>
              </div>
              <div className="softwareKnowledge">
                {designKnowledge.map((item, i) => {
                  return (
                    <p className="technologyCategories" key={i}>
                      {item.title}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </AnimatedSection>
      </div>

      {/* power platform */}
      <AnimatedSection delay={0.2}>
        <div
          className="skillContainer platformCard"
          style={{ minHeight: "unset" }}
        >
          <div className="row justify-content-between align-items-start">
            <div className="col-lg-9 col-md-6 col-7">
              <h3>Microsoft Power Platform</h3>
            </div>
            <div className="col-lg-3 col-md-6 col-5">
              <Link to="/web/power-platform-concepts">
                <button
                  className="chevronButton"
                  style={{
                    marginTop: 0,
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <p>View projects</p>
                  <ArrowForwardIcon />
                </button>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <p>
                I specialize in crafting solutions that simplify tasks and
                enhance productivity for organizations. With expertise covering
                both functional and technical dimensions of Power Platform, my
                focus extends to guiding design decisions throughout the
                development process, ensuring not only seamless functionality
                but also a user-friendly interface that enhances the overall
                user experience.
              </p>
            </div>
            <div className="col-lg-5 col-md-6 col-12">
              <div
                className="row justify-contents-end"
                style={{
                  marginTop: "1rem",
                }}
              >
                <div className="col-lg-4 col-md-4 col-4" id="powerapps">
                  <Tooltip title="Power Apps" placement="bottom" arrow>
                    <img
                      src={powerApps}
                      width="100%"
                      alt="power apps logo"
                      className="serviceIcons"
                    ></img>
                  </Tooltip>
                </div>

                <div className="col-lg-4 col-md-4 col-4" id="powerautomate">
                  <Tooltip title="Power Automate" placement="bottom" arrow>
                    <img
                      src={powerAutomate}
                      width="100%"
                      alt="power automate logo"
                      className="serviceIcons"
                    ></img>
                  </Tooltip>
                </div>

                <div className="col-lg-4 col-md-4 col-4" id="sharepoint">
                  <Tooltip title="SharePoint" placement="bottom" arrow>
                    <img
                      src={sharepoint}
                      width="100%"
                      alt="sharepoint logo"
                      className="serviceIcons"
                    ></img>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3
                style={{
                  margin: 0,
                  fontSize: "14px",
                  color: "var(--spanAccentColor)",
                }}
              >
                SharePoint SPFx & PnP Integration
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Experienced in seamlessly integrating SharePoint Framework
                (SPFx) solutions, including powerful Column Formatting
                techniques, to enhance SharePoint sites and libraries.
                Proficient in leveraging SharePoint Patterns and Practices (PnP)
                to deploy dynamic customizations and optimize user experiences.
                Skilled in tailoring SharePoint solutions to meet unique
                organizational needs, ensuring a user-friendly and visually
                appealing environment.
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </AnimatedSection>

      <div style={{ padding: "20px", paddingBottom: "3rem" }}>
        <AnimatedSection>
          <h3
            style={{
              marginTop: ".5rem",
            }}
          >
            CMS Management
          </h3>
        </AnimatedSection>
        <div className="row">
          {cmsKnowledge.map((item, i) => {
            return (
              <div className="col-lg-4 col-md-6 col-6" id="magento">
                <AnimatedSection key={i} delay={i * 0.2}>
                  <img
                    src={item.logo}
                    width="100%"
                    alt={`${item.name} icon`}
                    className="serviceIcons"
                  ></img>
                  <p>{item.name}</p>
                  <div className="softwareDescription">
                    <p>{item.description}</p>
                  </div>
                </AnimatedSection>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
