import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import { Accordion } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function PowerPlatfomCard(props) {
  const SubObject = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <>
        {props.subContent
          .sort((a, b) => {
            const newer = b.id;
            const older = a.id;
            return newer - older;
          })
          .map((item, i) => {
            const panel = `${props.title}-${i}`;
            return (
              <Accordion
                key={panel}
                expanded={expanded === panel}
                onChange={handleChange(panel)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h3
                    style={{
                      margin: 0,
                      fontSize: "14px",
                      color: "var(--spanAccentColor)",
                    }}
                  >
                    {item.title}
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p class="dateInd">{item.subYear}</p>
                  {item.subDescription.map((subElement, subInd) => {
                    return <p key={subInd}>{subElement}</p>;
                  })}
                  {props.subFunctionalities !== "" && (
                    <ul>
                      {item.subFunctionalities.map((subElement, subInd) => {
                        return (
                          <li style={{ margin: "0.5rem 0" }} key={subInd}>
                            {subElement}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </>
    );
  };

  return (
    <div className="outerProjectCard powerPlatform-outerCont">
      <div className="projectCard">
        <div class="projectDetail">
          <div className="flexContainer">
            <h3 style={{ marginBottom: "1.5rem" }}>{props.title}</h3>
            <CloseIcon onClick={props.closeAction} />
          </div>

          <div
            className="projectTechnologies"
            style={{
              marginBottom: "1.3rem",
              marginTop: "0",
            }}
          >
            {props.connectors.map((connectors, i) => {
              return (
                <span key={i} className="technologyCategories">
                  {connectors}
                </span>
              );
            })}
          </div>
          {props.description !== "" && <p>{props.description}</p>}
          {props.hasSubContent && <SubObject />}
          {props.keyfunctionalities !== "" && (
            <ul>
              {props.keyfunctionalities.map((list, i) => {
                return (
                  <li style={{ margin: "0.5rem 0" }} key={i}>
                    {list}
                  </li>
                );
              })}
            </ul>
          )}

          {props.img.length > 0 && (
            <img
              src={require(`../assets/project-items/${props.img}`)}
              width="100%"
              alt={props.title}
            />
          )}
          {props.year !== "" && (
            <div class="highlightInformation">
              <p>Date</p>
              <h4>{props.year}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
