import React from "react";
import { NavLink } from "react-router-dom";
import BlurHash from "./BlurHash";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AnimatedSection from "./AnimatedSections";

export default function KeyPhotoHighlights(props) {
  // constructing src
  const keyPhotoProject = require(`../assets/photography/${props.folderHandling}/${props.cardphoto}`);

  return (
    <AnimatedSection transition={{ delay: 0.2, duration: 0.7 }}>
      <div className="outerProjectCard">
        <div className="projectCard">
          <div className="topCategory">
            <div className="technologyCategories">
              <span>{props.category}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-4 col-12">
              <div className="photoGalleryHighlight">
                <BlurHash
                  src={keyPhotoProject}
                  alt={`${props.name} main project photo`}
                  hash={props.hashkey}
                ></BlurHash>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-12">
              <div className="projectDetail photoGallery">
                <p className="dateInd">{props.date}</p>
                <h3>{props.title}</h3>
                <h4>{props.subtitle}</h4>
                <p>{props.description}</p>
                <NavLink to={`/photography/${props.url}`}>
                  <button className="chevronButton">
                    <p>View reference gallery</p>
                    <ArrowForwardIcon />
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedSection>
  );
}
