import React from "react";
import AnimatedSection from "./AnimatedSections";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LockIcon from "@mui/icons-material/Lock";

export default function PowerPlatformLeadCard(props) {
  const locked = props.locked === true;

  return (
    <AnimatedSection transition={{ duration: 0.7, delay: 0.1 }}>
      <div className="outerProjectCard">
        <div className="projectCard platformCard">
          {locked && (
            <div className="lockedInd">
              <LockIcon alt="project is locked" width={100} />
            </div>
          )}
          <div className="projectDetail">
            <h3>Power Platform concepts</h3>
            <Link to="/web/power-platform-concepts">
              <button className="chevronButton">
                <p>Read more</p>
                <ArrowForwardIcon />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </AnimatedSection>
  );
}
